 <template>
    <!-- 样式组件 -->
    <div :class="'liefeng-info-view'" :style="'height:' + (data.height || height) * proportion + 'px;width:' + (data.width || width) * proportion + 'px'" v-if="!isParamComp">
        <img src="/images/tabs.png" />
    </div>
    <!-- 参数设置组件 -->
    <div class="liefeng-info-params" v-else>
        <Form :label-width="100" ref="infoForm">
            <FormItem label="栏签栏名称">
                <Input v-model.trim="data.name"></Input>
            </FormItem>
            <FormItem label="宽度">
                <Input type="Number" v-model.number="data.width" placeholder="宽度，默认：500" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="高度">
                <Input type="Number" v-model.number="data.height" placeholder="高度，默认：500" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="左边距">
                <Input type="Number" v-model.trim="data.marginLeft" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="右边距">
                <Input type="Number" v-model.trim="data.marginRight" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="上边距">
                <Input type="Number" v-model.trim="data.marginTop" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="下边距">
                <Input type="Number" v-model.trim="data.marginBottom" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="固定至顶部">
                <RadioGroup v-model="data.isFixed">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="选中背景">
                <ColorPicker v-model="data.backgroundColor" recommend @on-active-change="colorChange" />
            </FormItem>
            <FormItem label="数据来源">
                <RadioGroup v-model="data.dataFrom">
                    <Radio label="api">通过API或JSON数据获取</Radio>
                    <Radio label="menu">直接获取当前栏目的子栏目列表</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="API地址" v-if="data.dataFrom != 'menu'">
                <Input v-model.trim="data.api" placeholder="api优先于固定JSON数据" type="textarea" :autosize="true"></Input>
                <div style="color: blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
            </FormItem>
            <FormItem label="响应参数互换" v-if="data.dataFrom != 'menu' && data.api">
                <Input
                    v-model.trim="data.convertParams"
                    type="textarea"
                    rows="10"
                    placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
                ></Input>
                <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')">格式化 / 验证JSON合法性</Button>
                <APIreadme />
            </FormItem>
            <FormItem label="JSON数据" v-if="data.dataFrom != 'menu' && !data.api">
                <Input
                    v-model.trim="data.data"
                    type="textarea"
                    rows="15"
                    placeholder="例：[
                {
                 title:标题
                 value:标题索引值
                }
              ]"
                ></Input>
                <Button style="margin: 5px" type="success" @click="verifyJson('data')">格式化 / 验证JSON合法性</Button>
                <Button style="margin: 5px" type="info" @click="importDfData">导入例子</Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
/**
 * import LFTabs from "./components/LFTabs";
 * 标签页组件
 */
import APIreadme from "./APIreadme"
export default {
    components: { APIreadme },
    props: {
        isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
        height: { type: Number, default: 500 }, //高度，单位：px
        width: { type: Number, default: 500 }, //高度，单位：px
        data: {
            //组件数据，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        target: {
            //组件对象，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        proportion: {
            type: Number,
            default: () => {
                return 0
            },
        },
    },
    data() {
        return {
            initData: {
                //初始化用的数据，每个组件需要具备
                height: 500,
                width: 500,
                type: "LFTabs",
                api: "",
                data: null,
                name: "",
                backgroundColor: "#EBF7F7",
                isFixed: false,
                dataFrom: "api",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "0",
                marginBottom: "0",
            },
            dfData: JSON.stringify(
                [
                    {
                        title: "",
                        value: "",
                    },
                ],
                null,
                "\t"
            ),
        }
    },
    watch: {
        data: {
            handler(item, oldItem) {
                if (!item.name) item.name = this.$core.createID("SB")
                if (item.isFixed == null) item.isFixed = false
            },
            immediate: true,
        },
    },
    methods: {
        importDfData() {
            if (this.data.data) {
                this.$Modal.confirm({
                    title: "提示",
                    content: "编辑框已存在数据，是否覆盖？",
                    onOk: res => {
                        this.data.data = this.dfData
                    },
                })
            } else {
                this.data.data = this.dfData
            }
        },
        verifyJson(target) {
            //验证JSON,每个组件需要具备
            try {
                if (!this.data[target]) {
                    this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" })
                } else {
                    var json = JSON.parse(this.data[target])
                    this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" })
                    this.data[target] = JSON.stringify(json, null, "\t")
                }
            } catch (e) {
                this.$Modal.error({
                    title: "验证结果",
                    content: "JSON数据有误，请检查。",
                })
            }
        },
        colorChange(color) {
            this.data.backgroundColor = color
        },
        handleData() {
            this.$(this.target).css("height", (this.data.height || 500) * this.proportion + "px")
            this.$(this.target).css("width", (this.data.width || 500) * this.proportion + "px")
            this.$(this.target).css("marginLeft", Number(this.data.marginLeft) * this.proportion + "px")
            this.$(this.target).css("marginRight", Number(this.data.marginRight) * this.proportion + "px")
            this.$(this.target).css("marginTop", Number(this.data.marginTop) * this.proportion + "px")
            this.$(this.target).css("marginBottom", Number(this.data.marginBottom) * this.proportion + "px")
        },
    },
    mounted() {
        this.handleData()
    },
}
</script>
<style lang="less">
.liefeng-info-view {
    width: 100%;
    margin: 5px 0;
    overflow: hidden;
    background-color: #eee;
    img {
        width: 100%;
    }
}
</style>